import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar.tsx";
import { searchVideos } from "../../service/api.ts";
import "video.js/dist/video-js.css";
import "./SaerchPage.css";
import Modal from "react-modal";
import VideoPlayer from "../VideoPlayer/VideoPlayer.tsx";
import Slider from "react-slick";
import { CiPlay1 } from "react-icons/ci";
import { TfiClose } from "react-icons/tfi";
import { relative } from "path";
const SearchPage = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredVideo, setHoveredVideo] = useState(null);
  const [videoData, setVideoData] = useState<any>([]);

  const [trailerUrl, setTrailerUrl] = useState<any>({});
  const [isMouseMoving, setIsMouseMoving] = useState(true);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1441,
        settings: {
          slidesToShow: 3.2,
          slidesToScroll: 1,
          autoplay: false,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2.6,
          slidesToScroll: 1,
          autoplay: false,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          autoplay: false,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        setLoading(true);
        const urlParams = new URLSearchParams(window.location.search);
        const queryParam = urlParams.get("query");
        // console.log("params", queryParam);
        const data = await searchVideos(queryParam);

        setVideos(data);
      } catch (error) {
        console.error("Error fetching search results:", error);
      } finally {
        setLoading(false); // Set loading to false regardless of success or failure
      }
    };

    fetchSearchResults();
  }, [window.location.search]);

  const handleHover = (movie, e) => {
    const elm = e.target;
    const container = elm.closest(".slick-list");
    const slides = container.querySelectorAll(".slick-active");
    // const card = slides[slides.length - 1].getElementsByClassName(
    //   "row__posterLarge_hover"
    // )[0];
    // // card.style.right = "0%"
    // const card2 = slides[0].getElementsByClassName("row__posterLarge_hover")[0];
    // card2.style.left = "0%";
    setHoveredVideo(movie);
  };

  const handleHoverExit = (e) => {
    const elm = e.target;
    const container = elm.closest(".slick-list");
    const slides = container.querySelectorAll(".slick-active");
    // const card = slides[slides.length - 1].getElementsByClassName(
    //   "row__posterLarge_hover"
    // )[0];
    // // card.style.right = "auto"
    // const card2 = slides[0].getElementsByClassName("row__posterLarge_hover")[0];
    // // card2.style.left = "auto"
    setHoveredVideo(null);
  };

  const handleClick = (movie, e) => {
    const sources: any = [];

    if (movie.videoURL) {
      sources.push({
        src: movie.videoURL,
        type: "video/mp4",
        label: "4k",
      });
    }
    if (movie.videoURL1080p) {
      sources.push({
        src: movie.videoURL1080p,
        type: "video/mp4",
        label: "1080p",
      });
    }
    if (movie.videoURL720p) {
      sources.push({
        src: movie.videoURL720p,
        type: "video/mp4",
        label: "720p",
      });
    }
    if (movie.videoURL480p) {
      sources.push({
        src: movie.videoURL480p,
        type: "video/mp4",
        label: "480p",
      });
    }
    setVideoData({ data: movie, sources: sources });
    e.stopPropagation();
    setIsOpen(true);
    setTrailerUrl(movie);
    setHoveredVideo(null);
  };

  function afterOpenModal() {
    document.body.style.color = "#fff";
  }

  function closeModal() {
    setIsOpen(false);
  }

  if (isOpen) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "unset";
  }
  const isLargeRow = true;

  let timeout;

  const handleMouseMove = () => {
    setIsMouseMoving(true);
    (() => {
      clearTimeout(timeout);
      timeout = setTimeout(() => setIsMouseMoving(false), 5000);
    })();
  };

  return (
    <>
      <Navbar />
      <div style={{ position: "relative", marginTop: 85 }}>
        {loading ? (
          // Render loader while loading is true
          <h3
            style={{ color: "white", textAlign: "center", marginTop: "130px" }}
          >
            Loading...
          </h3>
        ) : videos.length === 0 ? (
          <h3
            style={{ color: "white", textAlign: "center", marginTop: "130px" }}
          >
            No results found ...
          </h3>
        ) : (
          <Slider {...settings}>
            {videos.map((movie: any) => (
              <div
                className="card"
                key={movie._id}
                onClick={(e) => handleClick(movie, e)}
                onMouseEnter={(e) => handleHover(movie, e)}
                onMouseLeave={handleHoverExit}
              >
                {/* {movie.videoName} */}
                <div
                  style={{ display: movie === hoveredVideo ? "block" : "none" }}
                  className={`row__posterLarge_hover`}
                >
                  {movie === hoveredVideo && (
                    <VideoPlayer
                      videoData={{
                        sources: [
                          {
                            src: movie.trailer,
                            type: "video/mp4",
                            label: "auto",
                          },
                        ],
                      }}
                      // className="hovered-video"
                      showControls={false}
                      aspectRatio="16:9"
                    />
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px",
                      paddingBottom: "0",
                      position: "absolute",
                      top: "40%",
                      left: "43%",
                    }}
                  >
                    <CiPlay1
                      color="#2fc0d1"
                      strokeWidth={2}
                      size={26}
                      onClick={(e) => handleClick(movie, e)}
                      style={{ cursor: "pointer" }}
                    />
                    {/* <IoBookmarksOutline color="white" size={20} /> */}
                  </div>
                  <h2
                    style={{
                      color: "#fff",
                      margin: "5px",
                      position: "absolute",
                      bottom: "10px",
                      left: "10px",
                      display: "-webkit-box",
                      lineClamp: "1",
                      boxOrient: "vertical",
                      overflow: "hidden",
                    }}
                  >
                    {movie.videoName}
                  </h2>
                </div>
                {/* ) */}
                {/* } */}
                <img
                  style={{ minHeight: 100 }}
                  className={`row__poster ${isLargeRow && "row__posterLarge"}`}
                  src={movie.thumbnail}
                  alt={movie.videoName}
                />
              </div>
            ))}
          </Slider>
        )}
      </div>
      {trailerUrl && (
        <Modal
          isOpen={isOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          className="abdull"
        >
          {isMouseMoving && (
            <button
              style={{
                position: "absolute",
                top: 40,
                zIndex: 9,
                right: 25,
                fontSize: 36,
                backgroundColor: "transparent",
                color: "#fff",
                padding: 7,
                border: "none",
              }}
              onClick={closeModal}
            >
              <TfiClose />
            </button>
          )}

          <div className="netflix-homepage" onMouseMove={handleMouseMove}>
            <div className="video-list">
              <div key={trailerUrl.id} className="video-item">
                {isMouseMoving && (
                  <h1
                    style={{
                      color: "#fff",
                      position: "absolute",
                      top: 20,
                      left: 25,
                      zIndex: 9,
                    }}
                  >
                    {trailerUrl.videoName}
                  </h1>
                )}

                <VideoPlayer
                  videoData={videoData}
                  showControls={isMouseMoving}
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default SearchPage;
