import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Modal from "react-modal";
import VideoPlayer from "../VideoPlayer/VideoPlayer.tsx";
// import { IoBookmarksOutline } from "react-icons/io5";
import Slider from "react-slick";
import { TfiClose } from "react-icons/tfi";
import { CiPlay1 } from "react-icons/ci";

import "video.js/dist/video-js.css";
import "./Row.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Row = ({ title, videos, isLargeRow, id, category }) => {
  const history = useHistory();

  const [trailerUrl, setTrailerUrl] = useState<any>({});
  const [videoData, setVideoData] = useState<any>({ data: null, sources: [] });
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredVideo, setHoveredVideo] = useState(null);
  const [isMouseMoving, setIsMouseMoving] = useState(true);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: false,
          arrows: false,
        },
      },
    ],

    // prevArrow: <div className="arrow prev" />,
    // nextArrow: <div className="arrow next" />,
  };

  const handleHover = (movie, e, isHover = false) => {
    // const elm = e.target;
    // const container = elm.closest(".slick-list");
    // const slides = container.querySelectorAll('.slick-active')
    // const card = slides[slides.length - 1].getElementsByClassName('row__posterLarge_hover')[0]
    // card.style.right = "0%"
    // const card2 = slides[0].getElementsByClassName('row__posterLarge_hover')[0]
    // card2.style.left = "0%"

    setHoveredVideo(movie);
  };

  const handleHoverExit = (e) => {
    // const elm = e.target;
    // const container = elm.closest(".slick-list");
    // const slides = container.querySelectorAll('.slick-active')
    // const card = slides[slides.length - 1].getElementsByClassName('row__posterLarge_hover')[0]
    // card.style.right = "auto"
    // const card2 = slides[0].getElementsByClassName('row__posterLarge_hover')[0]
    // card2.style.left = "auto"
    setHoveredVideo(null);
  };

  const handleClick = (movie, e) => {
    const sources: any = [];
    if (movie.videoURL) {
      sources.push({
        src: movie.videoURL,
        type: "video/mp4",
        label: "4k",
      });
    }

    if (movie.videoURL1080p) {
      sources.push({
        src: movie.videoURL1080p,
        type: "video/mp4",
        label: "1080p",
      });
    }
    if (movie.videoURL720p) {
      sources.push({
        src: movie.videoURL720p,
        type: "video/mp4",
        label: "720p",
      });
    }

    if (movie.videoURL480p) {
      sources.push({
        src: movie.videoURL480p,
        type: "video/mp4",
        label: "480p",
      });
    }

    setVideoData({ data: movie, sources: sources });

    e.stopPropagation();
    setIsOpen(true);
    setTrailerUrl(movie);
    setHoveredVideo(null);
  };

  function afterOpenModal() {
    document.body.style.color = "#fff";
  }

  function closeModal() {
    setIsOpen(false);
  }

  if (isOpen) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "unset";
  }

  // document.addEventListener('')

  useEffect(() => {
    if (window.innerWidth <= 576) {
      window.addEventListener("load", () => {
        if (window.MutationObserver) {
          const mutationObserver = new window.MutationObserver((e) => {
            const elms = document.querySelectorAll(".slick-track");
            elms.forEach((elm: Element) => {
              const elmItem = elm as HTMLElement;
              elmItem.style.transform = "translate3d(0px, 0px, 0px)";
            });
          });
          mutationObserver.observe(document, {
            childList: true,
            subtree: true,
          });
        }
      });
    }
  }, [videos]);

  const handleViewAllClick = (category) => {
    history.push(`/view-all?query=${category}`);
  };

  let timeout;

  const handleMouseMove = () => {
    setIsMouseMoving(true);
    (() => {
      clearTimeout(timeout);
      timeout = setTimeout(() => setIsMouseMoving(false), 5000);
    })();
  };
  const handleTouchStart = () => {
    setIsMouseMoving(true);
    (() => {
      clearTimeout(timeout);
      timeout = setTimeout(() => setIsMouseMoving(false), 10000);
    })();
  };
  return (
    <div id={id} className="carousel-container">
      <span style={{ display: "flex", alignItems: "center" }}>
        <h2 style={{ fontSize: "26px" }}>
          {title}{" "}
          <button
            onClick={() => handleViewAllClick(category)}
            style={{
              background: "none",
              color: "white",
              border: "none",
              cursor: "pointer",
              fontSize: "20px",
              marginLeft: "8px",
            }}
          >
            VIEW ALL <span className="arrow_right"></span>
          </button>
        </h2>
      </span>
      <Slider {...settings}>
        {videos.map((movie, idx) => (
          <div
            className="card"
            style={{ cursor: "pointer" }}
            key={`${idx}${movie.id}`}
            onClick={(e) => handleClick(movie, e)}
            onMouseEnter={(e) => handleHover(movie, e, true)}
            onMouseLeave={handleHoverExit}
          >
            {/* {hoveredVideo === movie && ( */}
            <div
              style={{ display: movie === hoveredVideo ? "block" : "none" }}
              className={`row__posterLarge_hover`}
            >
              {movie === hoveredVideo && (
                <VideoPlayer
                  videoData={{
                    sources: [
                      { src: movie.trailer, type: "video/mp4", label: "auto" },
                    ],
                  }}
                  showControls={false}
                  aspectRatio="16:9"
                />
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px",
                  paddingBottom: "0",
                  position: "absolute",
                  top: "40%",
                  left: "43%",
                }}
              >
                <CiPlay1
                  color="#2fc0d1"
                  strokeWidth={2}
                  size={26}
                  onClick={(e) => handleClick(movie, e)}
                  style={{ cursor: "pointer" }}
                />
                {/* <IoBookmarksOutline color="white" size={20} /> */}
              </div>
              <h2
                style={{
                  color: "#fff",
                  margin: "5px",
                  position: "absolute",
                  bottom: "10px",
                  left: "10px",
                  display: "-webkit-box",
                  lineClamp: "1",
                  boxOrient: "vertical",
                  overflow: "hidden",
                }}
              >
                {movie.videoName}
              </h2>
            </div>
            {/* ) */}
            {/* } */}
            <img
              style={{ minHeight: 100 }}
              className={`row__poster ${isLargeRow && "row__posterLarge"}`}
              src={movie.thumbnail}
              alt={movie.videoName}
            />
          </div>
        ))}
      </Slider>

      {trailerUrl && (
        <Modal
          isOpen={isOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          className="abdull"
        >
          {isMouseMoving && (
            <button
              className="modal__close"
              style={{
                position: "absolute",
                top: 20,
                zIndex: 9,
                right: 25,
                fontSize: 36,
                backgroundColor: "transparent",
                color: "#fff",
                padding: 0,
                border: "none",
              }}
              onClick={closeModal}
            >
              <TfiClose />
            </button>
          )}

          <div
            className="netflix-homepage"
            onMouseMove={handleMouseMove}
            onTouchStart={handleTouchStart}
          >
            <div className="video-list">
              <div key={trailerUrl.id} className="video-item">
                {isMouseMoving && (
                  <h1
                    style={{
                      color: "#fff",
                      position: "absolute",
                      top: 20,
                      left: 25,
                      zIndex: 9,
                    }}
                  >
                    {trailerUrl.videoName}
                  </h1>
                )}

                <VideoPlayer
                  videoData={videoData}
                  showControls={isMouseMoving}
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Row;
