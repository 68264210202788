import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "./resetPasswrod.css";
import { resetPassword } from "../../service/api.ts";
import {} from "react-router-dom";

const ResetPasswordForm = () => {
  const history = useHistory();

  const [token, setToken] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [passwordSuccessMessage, setPasswordSuccessrMessage] = useState("");

  const [resetPasswordData, setResetPasswordData] = useState({
    password: "",
    confirmPassword: "",
  });
  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm;
    return passwordRegex.test(password);
  };

  useEffect(() => {
    // Extract token from URL
    const urlParams = new URLSearchParams(window.location.search);
    const tokenParam = urlParams.get("token");
    if (tokenParam) {
      setToken(tokenParam);
    } else {
      // If token is not present in URL, redirect to home page or handle as needed
      setToken("");
    }
  }, [history]);

  const handleResetChange = (e) => {
    const { name, value } = e.target;
    setResetPasswordData({ ...resetPasswordData, [name]: value });
  };

  const handleResetSubmit = async (e) => {
    e.preventDefault();
    if (resetPasswordData.password !== resetPasswordData.confirmPassword) {
      setPasswordErrorMessage("Passwords do not match");
      setTimeout(() => {
        setPasswordErrorMessage("");
      }, 30000);
      return;
    }

    if (!validatePassword(resetPasswordData.password)) {
      setPasswordErrorMessage(
        "Password must contain at least 1 uppercase, 1 lowercase, 1 digit, and be at least 8 characters long"
      );
      setTimeout(() => {
        setPasswordErrorMessage("");
      }, 3000);
      return;
    }
    setIsLoading(true);
    try {
      const user: any = await resetPassword(token, resetPasswordData.password);
      if (user) {
        setPasswordSuccessrMessage("Password reset successfull");
        setTimeout(() => {
          setPasswordSuccessrMessage("");
          window.location.assign("/login");
        }, 3000);
      }
    } catch (error) {
      console.error("Reset Password Error:", error);
      setPasswordErrorMessage(
        error?.response?.data?.error ||
          "An error occurred during Reset Password"
      );
      setTimeout(() => {
        setPasswordErrorMessage("");
      }, 3000);
    } finally {
      setIsLoading(false);
    }
  };

  const goToHome = () => {
    history.push("/");
  };

  return (
    <div className="bg-login">
      <div className="flip-card">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src="./nefti-logo.png"
            width={130}
            height={60}
            style={{ marginTop: 30, marginBottom: 60 }}
            alt="no-logo"
            onClick={goToHome}
          />
        </div>
        <div style={{ position: "relative" }}>
          {passwordErrorMessage && (
            <p className="password-error-message">{passwordErrorMessage}</p>
          )}
          {passwordSuccessMessage && (
            <p className="success-message">{passwordSuccessMessage}</p>
          )}

          <h2 style={{ textAlign: "center" }}>RESET PASSWORD FORM</h2>
          <form onSubmit={handleResetSubmit}>
            <input
              className="input-custom"
              type="password"
              name="password"
              placeholder="Password"
              value={resetPasswordData.password}
              onChange={handleResetChange}
              required
            />
            <input
              className="input-custom"
              type="password"
              name="confirmPassword"
              placeholder="Confirm Password"
              value={resetPasswordData.confirmPassword}
              onChange={handleResetChange}
              required
            />
            <button
              className="btn-custom full"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? <div className="loader"></div> : "RESET PASSWORD"}
            </button>
            <a href="/login" className="resetLogin">
              Login
            </a>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
