import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Login from "./Pages/Login/login.tsx";
import Home from "./Pages/Home/home.tsx";
import TermsAndConditions from "./Pages/Terms/index.tsx";
import PrivacyPolicy from "./Pages/Privacy/index.tsx";
import { ROUTES } from "./utils/enums.ts";
import PrivateRoute from "./HOC/PrivateRoute.tsx";
import { Toaster } from "react-hot-toast";
import ResetPassword from "./Pages/Reset/ResetPassword.tsx";
import ResetPasswordForm from "./Pages/Reset/ResetPasswordForm.tsx";
import SearchPage from "./components/searchbar/SearchPage.tsx";
import VerificationEmail from "./components/VerificationPage/VerificationEmail.tsx";
import Contact from "./Pages/ContactUs/Contact.tsx";
import ViewAll from "./components/viewAll/ViewAll.tsx";

const App = () => {
  return (
    <Router>
      <div className='App'>
        <Switch>
          {/* <Route exact path={ROUTES.MAIN} component={Home} /> */}
          <Route exact path={ROUTES.TERMS} component={TermsAndConditions} />
          <Route exact path={ROUTES.RESET} component={ResetPassword} />
          <Route exact path={ROUTES.RESETFORM} component={ResetPasswordForm} />
          <Route exact path={ROUTES.PRIVACY} component={PrivacyPolicy} />
          <Route exact path={ROUTES.CONTACT} component={Contact} />
          <Route path={ROUTES.VERIFICATION} component={VerificationEmail} />
          <Route path={ROUTES.SEARCHPAGE} component={SearchPage} />
          <Route path={ROUTES.VIEWALL} component={ViewAll} />
          <PrivateRoute path={ROUTES.LOGIN} component={Login} />
          <PrivateRoute path={ROUTES.MAIN} component={Home} />
          {/* Redirect to login if route not found */}
          <Redirect to={ROUTES.LOGIN} />
        </Switch>
        <Toaster
          position='top-center'
          reverseOrder={false}
          toastOptions={{ className: "", duration: 2000 }}
        />
      </div>
    </Router>
  );
};

export default App;
