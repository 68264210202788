import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "../../service/api.ts";
import { setCookieItem } from "../../utils/localStore.ts";

import "./login.css";
import toast from "react-hot-toast";

const LoginSignupFlipCard = () => {
  const history = useHistory();
  const [isFlipped, setIsFlipped] = useState(false);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
    type: "0",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [signupErrorMessage, setSignupErrorMessage] = useState("");
  const [signupSuccessMessage, setSignupSuccessMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

  const [signupData, setSignupData] = useState({
    fullName: "",
    email: "",
    confirmPassword: "",
    password: "",
  });
  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm;
    return passwordRegex.test(password);
  };

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const handleSignupChange = (e) => {
    const { name, value } = e.target;
    setSignupData({ ...signupData, [name]: value });
  };

  const handleFlipToSignup = () => {
    setIsFlipped(true);
  };

  const handleFlipToLogin = () => {
    setIsFlipped(false);
    // setLoginData({});
    // setSignupData({});
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const user: any = await signInWithEmailAndPassword(
        loginData.email.toLowerCase(),
        loginData.password,
        loginData.type
      );
      if (user) {
        setCookieItem("email", loginData.email);
        setLoginData({ email: "", password: "", type: "0" });
        window.location.assign("/");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setErrorMessage("Invalid Credentials");
      } else if (error.response && error.response.status === 403) {
        setErrorMessage(
          error.response.data.error || "An error occurred during login"
        );
      } else {
        setErrorMessage("An error occurred during login");
      }
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignupSubmit = async (e) => {
    e.preventDefault();
    if (signupData.password !== signupData.confirmPassword) {
      setSignupErrorMessage("Passwords do not match");
      setTimeout(() => {
        setSignupErrorMessage("");
      }, 3000);
      return;
    }

    if (!validatePassword(signupData.password)) {
      setPasswordErrorMessage(
        "Password must contain at least 1 uppercase, 1 lowercase, 1 digit, and be at least 8 characters long"
      );
      setTimeout(() => {
        setPasswordErrorMessage("");
      }, 3000);
      return;
    }
    setIsLoading(true);
    try {
      const user: any = await createUserWithEmailAndPassword(
        signupData.fullName,
        signupData.email.toLowerCase(),
        signupData.password
      );
      setSignupSuccessMessage(
        "Verification Email has been sent! Check your email."
      );
      setIsFlipped(false);
      setTimeout(() => {
        setSignupSuccessMessage("");
      }, 3000);
      // window.location.assign("/");
      if (user) {
        setCookieItem("token", user.accessToken);
        setCookieItem("email", user.email);
        setLoginData({ email: "", password: "", type: "0" });
        history.push("/");
      }
    } catch (error) {
      console.error("Signup Error:", error);
      if (error.response && error.response.status === 400) {
        setSignupErrorMessage("User already exists with same email");
      } else {
        setSignupErrorMessage("An error occurred during sign up");
      }
      setTimeout(() => {
        setSignupErrorMessage("");
      }, 3000);
    } finally {
      setIsLoading(false);
    }
  };

  const goToHome = () => {
    history.push("/");
  };

  return (
    <div className="bg-login">
      <div className={`flip-card${isFlipped ? " flipped" : ""}`}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src="./nefti-logo.png"
            width={130}
            height={60}
            style={{ marginTop: 30, marginBottom: 56 }}
            alt="no-logo"
            onClick={goToHome}
          />
        </div>
        <div className="flip-card-inner">
          <div className="flip-card-front">
            {errorMessage && <p className="error-message"> {errorMessage} </p>}
            {signupSuccessMessage && (
              <p className="success-message"> {signupSuccessMessage} </p>
            )}
            {/* <p className='error-message'> error blooddddd </p> */}
            <form onSubmit={handleLoginSubmit}>
              <h2>LOG IN</h2>
              <input type="hidden" name="type" value={loginData.type} />
              <input
                className="input-custom"
                type="email"
                name="email"
                placeholder="Email"
                value={loginData.email}
                onChange={handleLoginChange}
                required
              />
              <input
                className="input-custom"
                type="password"
                name="password"
                placeholder="Password"
                value={loginData.password}
                onChange={handleLoginChange}
                required
              />
              <div className="login__btns text-center place-content-center">
                <button
                  className="btn-custom mr10"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? <div className="loader"></div> : "LOG IN"}
                </button>
                <button
                  className="btn-custom ml10"
                  type="button"
                  onClick={handleFlipToSignup}
                >
                  SIGN UP
                </button>
              </div>
            </form>
          </div>
          <div className="flip-card-back">
            {signupErrorMessage && (
              <p className="error-message"> {signupErrorMessage} </p>
            )}
            {passwordErrorMessage && (
              <p className="password-error-message"> {passwordErrorMessage} </p>
            )}
            <h2>SIGN UP</h2>
            <form onSubmit={handleSignupSubmit}>
              <input
                className="input-custom"
                type="text"
                name="fullName"
                placeholder="Full Name"
                value={signupData.fullName}
                onChange={handleSignupChange}
                required
              />
              <input
                className="input-custom"
                type="email"
                name="email"
                placeholder="Email"
                value={signupData.email}
                onChange={handleSignupChange}
                required
              />
              <input
                className="input-custom"
                type="password"
                name="password"
                placeholder="Password"
                value={signupData.password}
                onChange={handleSignupChange}
                required
              />
              <input
                className="input-custom"
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password"
                value={signupData.confirmPassword}
                onChange={handleSignupChange}
                required
              />
              <button
                className="btn-custom full"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? <div className="loader"></div> : "Create Account"}
              </button>
              <p
                style={{
                  textTransform: "uppercase",
                  fontSize: 13,
                  fontWeight: 700,
                }}
                onClick={handleFlipToLogin}
              >
                Login
              </p>
            </form>
          </div>
        </div>
      </div>
      <div style={{ position: "fixed", bottom: 20 }}>
        <p
          style={{
            color: "white",
            fontSize: 11,
            cursor: "default",
            letterSpacing: "0.1",
          }}
        >
          FORGOT PASSWORD?
          <a href="/reset-password">
            <span style={{ color: "#41c2d1", cursor: "pointer" }}> RESET</span>
          </a>
        </p>
      </div>
    </div>
  );
};

export default LoginSignupFlipCard;
