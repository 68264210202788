import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
// import { getCookieItem } from "../utils/localStore";
interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const isAuthenticated = !!localStorage.getItem("email");
  const isPublicPath = rest.path === "/login";
  if (isAuthenticated) {
    if (isPublicPath) {
      return <Redirect to='/' />;
    }
    return <Route {...rest} component={Component} />;
  }
  if (!isPublicPath) {
    return <Redirect to='/login' />;
  }
  return <Route {...rest} component={Component} />;
};

export default PrivateRoute;
