export enum ROUTES {
  MAIN = "/",
  TERMS = "/terms",
  PRIVACY = "/privacy",
  CONTACT = "/contact-us",
  LOGIN = "/login",
  RESET = "/reset-password",
  RESETFORM = "/reset-password-form",
  SEARCHPAGE = "/search-results",
  VIEWALL = "/view-all",
  VERIFICATION = "/verification",
}
