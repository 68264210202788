// Home.tsx
import React, { useEffect, useState } from "react";
import { FiArrowUpCircle } from "react-icons/fi";
import { useHistory, useLocation } from "react-router-dom";
import Banner from "../../components/Banner/Banner.tsx";
import Footer from "../../components/Footer/Footer.tsx";
import Navbar from "../../components/Navbar/Navbar.tsx";
import Row from "../../components/Row/Row.tsx";
import { fetchVideos } from "../../service/api.ts";
import "./home.css";

const Home = () => {
  const history = useHistory();
  const location = useLocation();
  const [videos, setVideos] = useState<any>([]);
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const videoList = await fetchVideos();
        setVideos(videoList);
      } catch (error) {
        console.error("Error fetching videos:", error);
        if (error.response && error.response.status === 401) {
          // Clear local storage
          localStorage.clear();
          // Redirect to login page
          history.push("/login");
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setShowScrollButton(scrollTop > 200);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="App">
      <Navbar />
      <Banner movie={videos?.find((video: any) => video.isFeatured)} />
      <Row
        id="films"
        title={"NEFTI Films"}
        videos={videos.filter((video: any) => video.category.includes("NEFTi"))}
        isLargeRow={true}
        category={"NEFTi"}
      />
      <Row
        id="spotlights"
        title={"NEFTi All Stars"}
        videos={videos.filter((video: any) =>
          video.category.includes("spotlights")
        )}
        isLargeRow={true}
        category={"spotlights"}
      />

      <Row
        id="originals"
        title={"NEFTI Originals"}
        videos={videos.filter((video: any) =>
          video.category.includes("originals")
        )}
        isLargeRow={true}
        category={"originals"}
      />
      <Row
        id="trendings"
        title={"Trending Now"}
        videos={videos.filter((video: any) =>
          video.category.includes("trendings")
        )}
        isLargeRow={true}
        category={"trendings"}
      />
      <Footer />
      {showScrollButton && (
        <button onClick={scrollToTop} className="scrollToTopButton">
          <FiArrowUpCircle size={32} />
        </button>
      )}
    </div>
  );
};

export default Home;
