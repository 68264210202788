export const setCookieItem = (key, value) => {
    localStorage.setItem(key, value);
}

export const getCookieItem = (key) => {
    return localStorage.getItem(key);
}

export const deleteCookieItem = (key) => {
    localStorage.removeItem(key);
}