// Footer.js

import React from "react";
import "./Footer.css"; // Import the Footer component's CSS file

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="footer-col">
            <img className="footer__logo" src="nefti-logo.png" alt="Logo" />
            <p>&copy; 2021-2024 NEFTi</p>
          </div>
          <div className="footer-col">
            <h4>About NEFTi</h4>
            <p className="footer__description">
              NEFTi is your ultimate destination for a premium streaming
              experience. Dive into a world of unlimited entertainment with our
              vast library of movies, TV shows, documentaries, and exclusive
              originals
            </p>
          </div>
          <div className="footer-col">
            <h4>Links</h4>
            <ul className="footer__links">
              <li>
                <a href="/terms">Terms of Use</a>
              </li>
              <li>
                <a href="/privacy">Privacy Policy</a>
              </li>
              <li>
                <a href="/contact-us">Contact Us</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
