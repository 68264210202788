import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { FaPlay } from "react-icons/fa";
import { IoBookmarksOutline } from "react-icons/io5";
import { BsBadge3DFill } from "react-icons/bs";
import { BsBadge4K } from "react-icons/bs";
import { BiCameraMovie } from "react-icons/bi";
import { TfiClose } from "react-icons/tfi";
import { SlBadge } from "react-icons/sl";

import VideoPlayer from "../VideoPlayer/VideoPlayer.tsx";

import "./Banner.css";

const Banner = ({ movie }) => {
  const [showModal, setShowModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [videoData, setVideoData] = useState<any>({ data: null, sources: [] });

  const handleClick = () => {
    const sources: any = [];

    if (movie?.videoURL) {
      sources.push({
        src: movie.videoURL,
        type: "video/mp4",
        label: "4k",
      });
    }
    if (movie?.videoURL1080p) {
      sources.push({
        src: movie?.videoURL1080p,
        type: "video/mp4",
        label: "1080p",
      });
    }
    if (movie.videoURL720p) {
      sources.push({
        src: movie?.videoURL720p,
        type: "video/mp4",
        label: "720p",
      });
    }
    if (movie.videoURL480p) {
      sources.push({
        src: movie.videoURL480p,
        type: "video/mp4",
        label: "480p",
      });
    }
    setVideoData({ data: movie, sources: sources });
    setShowModal(true);
  };

  function afterOpenModal() {
    document.body.style.color = "#fff";
  }

  function handleCloseVideo() {
    setShowModal(false);
  }

  const handleHover = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  if (showModal) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "unset";
  }

  return (
    <>
      <Modal
        isOpen={showModal}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseVideo}
        style={{ zIndex: 1000, backgroundColor: "transparent", border: "none" }}
        className="abdull"
      >
        <button
          style={{
            position: "absolute",
            top: 20,
            zIndex: 9,
            right: 25,
            fontSize: 36,
            backgroundColor: "transparent",
            color: "white",
            padding: 0,
            border: "none",
            cursor: "pointer",
          }}
          onClick={handleCloseVideo}
        >
          <TfiClose />
        </button>
        <div className="netflix-homepage">
          <div className="video-list">
            <div className="video-item">
              <h1
                className="drop-shadow-2xl"
                style={{
                  color: "white",
                  position: "absolute",
                  top: 20,
                  left: 25,
                  zIndex: 9,
                }}
              >
                {movie?.videoName}
              </h1>
              <VideoPlayer videoData={videoData} />
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={detailModal}
        onAfterOpen={afterOpenModal}
        onRequestClose={() => setDetailModal(false)}
        style={{ zIndex: 1000 }}
        className="abdull2"
      >
        <header
          className="modal-banner"
          style={{
            backgroundSize: "cover",
            backgroundImage: `url(${movie?.thumbnail})`,
          }}
        >
          <div className="banner__contents">
            {/* <h1 className="banner__title">{<img src={movie.titleLogo} style={{ maxWidth: 370, maxHeight: 150 }} />}</h1> */}
            {/* <h1 className="banner__title">{movie?.title || movie.name || movie?.original_name}</h1> */}
            {movie && movie?.cast && movie?.cast.length && (
              <div className="banner__buttons bg-shadow">
                <TfiClose
                  size={30}
                  style={{ float: "right", cursor: "pointer" }}
                  onClick={() => setDetailModal(false)}
                />
                <IoBookmarksOutline size={25} className="movie_details" />
                <SlBadge size={25} className="movie_details" />
                <BsBadge4K size={25} className="movie_details" />
                <BsBadge3DFill size={25} className="movie_details" />
                <BiCameraMovie size={25} className="movie_details" />

                <div className="meta_container">
                  <div className="banner__metadata">
                    {movie?.duration && <p>{movie?.duration}</p>}
                    {movie?.description && (
                      <h2 className="banner__description line-clamp-4">
                        {movie?.description}
                      </h2>
                    )}
                  </div>
                  <div className="banner__additional-info">
                    <div className="banner__info-column">
                      {movie?.videoName && (
                        <>
                          <p
                            style={{
                              fontWeight: "bolder",
                              fontSize: 18,
                              marginTop: 0,
                              marginBottom: 4,
                            }}
                          >
                            Title:
                          </p>
                          <p style={{ margin: 0, fontSize: 22 }}>
                            {movie?.videoName}
                          </p>
                        </>
                      )}
                      {movie?.director?.length > 0 && (
                        <>
                          <p
                            style={{
                              fontWeight: "bolder",
                              fontSize: 18,
                              marginTop: 15,
                              marginBottom: 4,
                            }}
                          >
                            Director:
                          </p>
                          <p style={{ margin: 0, fontSize: 22 }}>
                            {movie?.director.map((d) => d.trim()).join(", ")}
                          </p>
                        </>
                      )}
                      {movie?.cast.length > 0 && (
                        <>
                          <p
                            style={{
                              fontWeight: "bolder",
                              fontSize: 18,
                              marginTop: 15,
                              marginBottom: 4,
                            }}
                          >
                            Cast:
                          </p>
                          <p style={{ margin: 0, fontSize: 22 }}>
                            {movie?.cast.map((c) => c.trim()).join(", ")}
                          </p>
                        </>
                      )}
                      {movie?.genre?.length > 0 && (
                        <>
                          <p
                            style={{
                              fontWeight: "bolder",
                              fontSize: 18,
                              marginTop: 15,
                              marginBottom: 4,
                            }}
                          >
                            Genre:
                          </p>
                          <p style={{ margin: 0, fontSize: 22 }}>
                            {movie?.genre.map((g) => g.trim()).join(", ")}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* <div className="banner__fadeBottom" /> */}
        </header>
      </Modal>
      <header
        className="banner"
        style={{
          backgroundSize: "cover",
          backgroundImage: !hovered ? `url(${movie?.thumbnail})` : "",
          backgroundPosition: "50% 10%",
        }}
        onMouseEnter={handleHover}
        onMouseLeave={handleMouseLeave}
      >
        {hovered && (
          <video autoPlay loop muted className="video__banners">
            <source src={movie?.trailer} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
        <div className="banner__contents">
          <h1 className="banner__title">
            {movie?.titleLogo ? (
              <img
                src={movie?.titleLogo}
                style={{ maxWidth: 370, maxHeight: 150 }}
              />
            ) : (
              movie?.videoName
            )}
          </h1>
          <h1 className="banner__description line-clamp-4">
            {movie?.description}
          </h1>
          <div className="banner__buttons">
            <button className="btn banner__button" onClick={handleClick}>
              <FaPlay size={12} style={{ marginRight: 5 }} /> PLAY
            </button>
            <button
              className="btn banner__button hollow-btn"
              onClick={() => setDetailModal(true)}
            >
              DETAILS
            </button>
          </div>
        </div>
        <div className="banner__fadeBottom" />
      </header>
    </>
  );
};

export default Banner;
