import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./resetPasswrod.css";
import { requestPasswordReset } from "../../service/api.ts";
import toast from "react-hot-toast";

const ResetPassword = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [resetPasswordData, setResetPasswordData] = useState({
    email: "",
  });

  const handleResetChange = (e) => {
    const { name, value } = e.target;
    setResetPasswordData({ ...resetPasswordData, [name]: value });
  };

  const handleResetSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const user: any = await requestPasswordReset(
        resetPasswordData.email.toLowerCase()
      );
      if (user) {
        setSuccessMessage("Email with the reset Link has been sent");
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);
        // window.location.assign("/login");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        toast.error("User not Resgistered");
      } else if (error.response && error.response.status === 400) {
        toast.error(
          error?.response?.data?.error ||
            "An error occurred during Reset Password"
        );
      }
      setTimeout(() => {
        setPasswordErrorMessage("");
      }, 3000);
    } finally {
      setIsLoading(false);
    }
  };

  const goToHome = () => {
    history.push("/");
  };

  return (
    <div className="bg-login">
      <div className="flip-card">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src="./nefti-logo.png"
            width={130}
            height={60}
            style={{ marginTop: 30, marginBottom: 50 }}
            alt="no-logo"
            onClick={goToHome}
          />
        </div>
        <div style={{ position: "relative" }}>
          {passwordErrorMessage && (
            <p className="password-error-message" style={{ top: -44 }}>
              {" "}
              {passwordErrorMessage}{" "}
            </p>
          )}
          {successMessage && (
            <p className="success-message"> {successMessage} </p>
          )}
          <h2 style={{ textAlign: "center" }}>RESET PASSWORD</h2>
          <form onSubmit={handleResetSubmit}>
            <input
              className="input-custom"
              type="email"
              name="email"
              placeholder="Email"
              value={resetPasswordData.email}
              onChange={handleResetChange}
              required
            />
            <button
              className="btn-custom full"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? <div className="loader"></div> : "RESET PASSWORD"}
            </button>
            <a href="/login" className="resetLogin">
              Login
            </a>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
