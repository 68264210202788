import React from "react";
import Navbar from "../../components/Navbar/Navbar.tsx";

const Contact = () => {
  return (
    <>
      <Navbar />
      <div style={{ textAlign: "center", marginTop: "calc(50vh - 18px)" }}>
        <h2>Contact Us at </h2>
        <h1>
          <a style={{ color: "#2fc1d2" }} href='mailto:info@nefti.com'>
            info@nefti.com
          </a>
        </h1>
      </div>
    </>
  );
};

export default Contact;
