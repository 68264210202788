import React from 'react';
import Navbar from '../../components/Navbar/Navbar.tsx';
import Footer from '../../components/Footer/Footer.tsx';
import './index.css';

const TermsAndConditions = () => {
    const termsContent = `Nefti Terms & Conditions
    Effective Date: Jan 1, 2024
    Overview: Nefti offers a range of products related to movies and series available on Nefti, including experiences and other services. The term "Shop" refers to the online platform accessible at nefti and its subdomains, including all its features, functionalities, and associated content and software. 
    Agreement to Terms: By using the Shop, you agree to these Terms of Service. If you disagree with any part of these terms, you should not use the Shop.
    User Account: Creating a user account enhances your shopping experience. It's important to provide accurate information and keep it updated. Note that accounts for nefti and NeftiNetwork.com can be separate.
    Use of Site: The Shop aims to be available 24/7 but may occasionally be down for maintenance or due to technical issues. Harassment, impersonation, and uploading offensive content are prohibited.
    Product Descriptions: We strive for accuracy in our product and experience descriptions but cannot guarantee that all information is always accurate or error-free.
    Availability: Products and tickets are available on a first-come, first-served basis, and availability cannot be guaranteed.
    Third-Party Links: The Shop may include links to third-party sites, over which we have no control.
    Purchases: We reserve the right to limit orders and purchases to prevent commercial buying.
    Comments & Feedback: Submitted feedback will be governed by our Privacy Statement.
    User Generated Content (UGC): By submitting UGC, you grant Nefti a license to use it according to the terms specified.
    Intended Use: Access to the Shop is for personal, non-commercial use. You must not misuse the Shop's content or features.
    Marketplace: The Shop may feature a marketplace with third-party vendors, whose terms and conditions apply to purchases from them.
    Disclaimers: The Shop and its content are provided "as is" without any warranties. We are not liable for certain damages to the extent permitted by law.
    Intellectual Property: Content on the Shop is protected by intellectual property laws. Unauthorised use is prohibited.
    Arbitration: Disputes under these terms will be resolved by arbitration, except as permitted by law.
    Privacy: Please review our Privacy Statement for details on how we handle your personal information.
    Miscellaneous: These Terms are governed by Delaware law, excluding its conflict of laws provisions. We reserve the right to update these Terms and assign our agreement with you.`

    return (
        <>
            <Navbar />
            <div className="terms-container">
                <h1 className="terms-title">Terms & Conditions</h1>
                <p className="terms-content">{termsContent}</p>
            </div>
            <Footer />
        </>
    );
}

export default TermsAndConditions;
