import React, { useEffect, useRef, useState } from "react";
import videojs from "video.js";
import Player from "video.js/dist/types/player";
import "video.js/dist/video-js.css";
import "./video.css";

interface VideoControls {
  controlBar: {
    el: () => any;
  };
}

const VideoPlayer = ({
  videoData,
  videoUrl = null,
  showControls = true,
  showBackwardButton = true,
  showForwardButton = true,
  autoplay = true,
  backwardSeconds = 10,
  forwardSeconds = 10,
  aspectRatio = "16:9",
}) => {
  const videoRef = useRef(null);
  const playerRef = useRef<Player | null>(null);
  const [currentSource, setCurrentSource] = useState(videoData?.sources[0]);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const createBackwardButton = (player) => {
    const backButton = document.createElement("button");
    backButton.className = "vjs-control vjs-button seek_btn";
    backButton.innerHTML = `<< ${backwardSeconds}s`;
    backButton.onclick = () => {
      player.currentTime(player.currentTime() - backwardSeconds);
    };
    player.controlBar.el().appendChild(backButton);
  };

  const createForwardButton = (player) => {
    const forwardButton = document.createElement("button");
    forwardButton.className = "vjs-control vjs-button seek_btn";
    forwardButton.innerHTML = `${forwardSeconds}s >>`;
    forwardButton.onclick = () => {
      player.currentTime(player.currentTime() + forwardSeconds);
    };
    player.controlBar.el().appendChild(forwardButton);
  };

  const addQualityPickerToControlBar = (player) => {
    const qualityPickerContainer = document.createElement("div");
    qualityPickerContainer.className = "vjs-quality-picker-container";

    const qualityPickerDropdown = document.createElement("select");
    qualityPickerDropdown.className = "vjs-quality-picker-dropdown";
    qualityPickerDropdown.innerHTML = videoData?.sources
      .map(
        (source) => `<option value="${source?.label}">${source?.label}</option>`
      )
      .join("");

    qualityPickerDropdown.value = currentSource?.label;
    qualityPickerDropdown.addEventListener("change", (e: any) => {
      const selectedLabel = e.target.value;
      const selectedSource = videoData?.sources.find(
        (source) => source.label === selectedLabel
      );
      changeQuality(selectedSource);
    });

    qualityPickerContainer.appendChild(qualityPickerDropdown);
    player.controlBar.el().appendChild(qualityPickerContainer);
  };

  const addInfoSignToControlBar = (player) => {
    const infoSign = document.createElement("span");
    infoSign.className = "info-sign";
    infoSign.innerHTML = "ⓘ";
    infoSign.style.cursor = "pointer";
    infoSign.style.fontSize = "22px";
    infoSign.style.marginLeft = "20px";
    const controlBarContainer = player.controlBar
      .el()
      .getElementsByClassName("vjs-quality-picker-container")[0];
    if (controlBarContainer) {
      controlBarContainer.appendChild(infoSign);
    } else {
      player.controlBar.el().appendChild(infoSign);
    }

    infoSign.addEventListener("mouseenter", () => setIsTooltipVisible(true));
    infoSign.addEventListener("mouseleave", () => setIsTooltipVisible(false));
  };
  const changeQuality = (source) => {
    console.log(source);
    if (playerRef.current) {
      const currentTime = playerRef.current.currentTime();
      playerRef.current.src(source);
      playerRef.current.currentTime(currentTime);
      playerRef.current.play();
      setCurrentSource(source);
    }
  };

  useEffect(() => {
    // console.log("yolo");
    if (playerRef.current) {
      playerRef.current.controls(showControls);
    }
  }, [showControls]);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      const player = videojs(videoElement, {
        controls: showControls,
        autoplay: autoplay,
        loop: false,
        fluid: true,
        aspectRatio,
        sources: [currentSource],
      });
      playerRef.current = player;

      if (showBackwardButton) {
        createBackwardButton(player);
      }

      if (showForwardButton) {
        createForwardButton(player);
      }

      addQualityPickerToControlBar(player);

      addInfoSignToControlBar(player);

      // Handle responsive behavior here
      const handleResponsive = () => {
        if (window.innerWidth <= 1441) {
          const backButton = (player as unknown as VideoControls).controlBar
            .el()
            .getElementsByClassName("backward-button")[0];
          if (backButton) {
            backButton.style.display = "none";
          }
        } else {
          const backButton = (player as unknown as VideoControls).controlBar
            .el()
            .getElementsByClassName("backward-button")[0];
          if (backButton) {
            backButton.style.display = "block";
          }
        }
      };

      handleResponsive();

      // Add event listener for window resize
      window.addEventListener("resize", handleResponsive);

      return () => {
        window.removeEventListener("resize", handleResponsive);
        if (player) {
          player.dispose();
        }
      };
    }
  }, [videoData]);

  return (
    <div>
      <div data-vjs-player>
        <video
          ref={videoRef}
          className={`video-js vjs-default-skin`}
          style={{ borderRadius: "6px" }}
        ></video>
      </div>

      {isTooltipVisible && (
        <div
          className="video-tooltip"
          style={{
            position: "absolute",
            bottom: "80px",
            right: "50px",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            padding: "30px",
            borderRadius: "5px",
            width: "300px",
            zIndex: 10,
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {videoData?.data?.videoName && <h4>{videoData?.data?.videoName}</h4>}
          {videoData?.data?.description && (
            <p>{videoData?.data?.description}</p>
          )}
          {videoData?.data?.director.length > 0 && (
            <p>
              <strong>Director:</strong>{" "}
              {videoData?.data.director.map((d) => d.trim()).join(", ")}
            </p>
          )}
          {videoData?.data?.cast.length > 0 && (
            <p>
              <strong>Cast:</strong>{" "}
              {videoData?.data.cast.map((c) => c.trim()).join(", ")}
            </p>
          )}
          {videoData?.data?.genre.length > 0 && (
            <p>
              <strong>Genre:</strong>{" "}
              {videoData?.data.genre.map((g) => g.trim()).join(", ")}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
