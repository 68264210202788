import React from "react";
import "./DropdownMenu.css";

const DropdownMenu = (props) => {
  const handleNavClick = (scrollTo) => {
    const sectionList: any = document.getElementById(scrollTo);
    const y = sectionList.getBoundingClientRect().top + window.scrollY;
    window.scroll({
      top: y - 70,
      behavior: "smooth",
    });
  };

  return (
    <div
      className={`dropdown-menu ${props.isOpen ? "open" : ""}`}
      position={"absolute"}
    >
      <ul>
        <li onClick={() => handleNavClick("films")}>NEFTI Films</li>
        <li onClick={() => handleNavClick("spotlights")}>NEFTi All Stars</li>
        <li onClick={() => handleNavClick("originals")}>NEFTI Originals</li>
        <li onClick={() => handleNavClick("trendings")}>Trending Now</li>
      </ul>
    </div>
  );
};

export default DropdownMenu;
