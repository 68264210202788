import React from 'react';
import './index.css';
import Footer from '../../components/Footer/Footer.tsx';
import Navbar from '../../components/Navbar/Navbar.tsx';


const PrivacyPolicy = () => {
    const termsContent = `Nefti Privacy Statement Summary
    Effective Date: January 1, 2024
    Overview: This summary outlines how Nefti handles your personal information related to the Nefti service, including collection, use, and sharing practices.
    Contact Information:
    For general inquiries or assistance, visit our help center.
    For privacy-specific queries, contact our Data Protection Officer at privacy@nefti.com.
    Collection of Information:
    Information You Provide: Includes name, email, payment details, phone number, and any other identifiers (e.g., in-game names), along with ratings, preferences, and feedback.
    Information Collected Automatically: Encompasses service usage data, device identifiers, interaction with our content and ads, network information, and data collected via cookies and other tracking technologies.
    Information from Partners: Obtained from companies you have relationships with, like internet service providers or device manufacturers, and may include service activation details and usage data.
    Information from Other Sources: Includes publicly available data or information from third-party services integrated with Nefti.
    Use of Information:
    To enhance and personalize the Nefti service, including content recommendations.
    To manage your account, process payments, and communicate with you regarding the service.
    For security, fraud prevention, and to enforce our terms.
    To analyze and improve our service and marketing efforts.
    Disclosure of Information:
    Within the Nefti family of companies for operational reasons.
    To Service Providers assisting with our service delivery.
    To Partners for integrating their services with Nefti.
    For promotional offers, legal obligations, or in the event of a business transfer.
    Access and Control:
    Use the "Account" section on our website to access and update your information.
    You can manage communication preferences and exercise rights regarding your personal information via the "Account" section or by contacting privacy@nefti.com.
    Data Retention and Security:
    We retain information as necessary for service provision and comply with legal requirements, ensuring it's securely destroyed when no longer needed.
    We implement measures to protect your personal information against unauthorized access and use.
    Choices and Technologies:
    Offers control over email, text messages, and push notifications.
    Provides options for managing cookies, device identifiers, and behavioral advertising.
    Children's Use: The Nefti service requires users to be 18 or older. Minors may use the service under the supervision of a parent or guardian.`

    return (
        <>
            <Navbar />
            <div className="privacy-container">
                <h1 className="privacy-title">Privacy Policy</h1>
                <p className="privacy-content">{termsContent}</p>
            </div>
            <Footer />
        </>
    );
}

export default PrivacyPolicy;
