import React, { useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { TfiClose } from "react-icons/tfi";

import { getCookieItem } from "../../utils/localStore.ts";
import { CiUser } from "react-icons/ci";
import { FiArrowLeft } from "react-icons/fi";
import DropdownMenu from "../DropdownMenu/DropdownMenu.tsx";
import "./Navbar.css";
import { Button } from "react-bootstrap";

const Navbar = () => {
  const history = useHistory();
  const location = useLocation();
  const wrapperRef = useRef(null);
  const wrapRef = useRef(null);

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [loggedUserEmail] = useState(getCookieItem("email"));
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [query, setQuery] = useState("");

  const toggleSearchBar = () => {
    setShowSearchBar((prev) => !prev);
  };

  const closeSearchBar = () => {
    setShowSearchBar(false);
  };

  const toggleMenu = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      setShow(window.scrollY > 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setMenuIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapRef.current && !wrapRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapRef]);

  const handleLogout = () => {
    // Clear local storage
    localStorage.clear();
    // Redirect to login page
    history.push("/login");
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = () => {
    if (query.trim() !== "") {
      history.push(`/search-results?query=${query}`);
    }
  };

  const isHomePage = location.pathname === "/";

  return (
    <div className={`nav ${show ? "nav__black" : ""}`} position={"relative"}>
      {isHomePage ? (
        <div ref={wrapperRef} className='nav__left' position={"absolute"}>
          <button className='nav__menuBtn' onClick={toggleMenu}>
            <img className='img-size-height' src='./menu.png' alt='menu' />
          </button>
          <DropdownMenu isOpen={menuIsOpen} />
        </div>
      ) : (
        <div>
          <button
            style={{
              color: "white",
              background: "none",
              border: "none",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              fontSize: "20px",
            }}
            onClick={() => history.push("/")}
          >
            <FiArrowLeft style={{ marginRight: "8px" }} />
            Home
          </button>
        </div>
      )}
      <div className='nav__center'>
        <img
          className='nav__logo'
          src='./nefti-logo.png'
          alt='nefti-logo'
          onClick={() => history.push("/")}
        />
      </div>
      <div className='nav__right'>
        <div className={`nav__searchBar ${showSearchBar ? "open" : "close"}`}>
          <button className='nav__searchIcon' onClick={toggleSearchBar}>
            {!showSearchBar ? (
              <img
                className='img-size-height'
                src='./search.png'
                alt='search'
              />
            ) : (
              <TfiClose size={18} color='white' className='search__icon' />
            )}
          </button>
          {showSearchBar && (
            <div className='nav__searchBar'>
              <input
                type='text'
                placeholder='Search'
                onChange={(e) => setQuery(e.target.value)}
                onKeyDown={handleKeyDown}
                onBlur={closeSearchBar}
              />
            </div>
          )}
        </div>
        {loggedUserEmail ? (
          <>
            {/* <CiUser color='white' size={20} /> */}
            <div ref={wrapRef} className='profile_main'>
              <button
                className='profile_btn'
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                {loggedUserEmail.split("@")[0][0].toUpperCase()}
              </button>
              {isDropdownOpen && (
                <div className='profile_dropdown'>
                  <button
                    onClick={handleLogout}
                    className='block w-full px-4 py-2 text-gray-800 hover:bg-gray-100 '
                  >
                    Logout
                  </button>
                </div>
              )}
            </div>
          </>
        ) : (
          <button
            className='nav__profileIcon'
            onClick={() => history.push("/login")}
          >
            <CiUser color='white' size={20} />{" "}
            <span
              style={{
                color: "white",
                display: "inline-block",
                position: "relative",
                top: "-2px",
              }}
            >
              Login
            </span>
          </button>
        )}
      </div>
    </div>
  );
};

export default Navbar;
