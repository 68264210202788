import React from "react";
import { useLocation, useHistory } from "react-router-dom";

const VerificationEmail = () => {
  const history = useHistory();
  const searchParams = new URLSearchParams(useLocation().search);
  const status = searchParams.get("status");
  const message = searchParams.get("message");
  const color = status === "success" ? "cyan" : "red";
  const goToHome = () => {
    history.push("/");
  };
  return (
    <div className='bg-login'>
      <div className="flip-card">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src='./nefti-logo.png'
            width={130}
            height={60}
            style={{ marginTop: 30, marginBottom: 50 }}
            alt='no-logo'
            onClick={goToHome}
          />
        </div>
        <div style={{position: "relative", textAlign: "center"}}>
          <h2 style={{ marginBottom: 15, fontSize: 20 }}>{status === "success" ? "Success!" : "Error!"}</h2>
          <p style={{ color, fontSize: 18 }}>
            {message}{" "}
          </p>
          {status === "success" ? (
            <a href='/login' className="login-verify">
              Login here
            </a>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default VerificationEmail;
