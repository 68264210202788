import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;
axios.interceptors.request.use(
  (config: any) => {
    //console.log("config interceptor: ", config);
    // config.timeout = 40000;

    const AUTH_TOKEN = `Bearer ${localStorage.getItem("token")}` || "";
    config.headers = config.headers || {};
    if (!config.headers["Authorization"]) {
      config.headers["Authorization"] = AUTH_TOKEN;
      //   console.log("Auth Token", AUTH_TOKEN);
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const signInWithEmailAndPassword = async (email, password, type) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/signin`, {
      email,
      password,
      type,
    });
    if (data.token) {
      localStorage.setItem("token", data.token);
      return true;
    }
    return false;
  } catch (error) {
    console.error("Login Error:", error.message);
    throw error;
  }
};

export const createUserWithEmailAndPassword = async (
  fullName,
  email,
  password
) => {
  try {
    const signUpResponse = await axios.post(`${BASE_URL}/signup`, {
      fullName,
      email,
      password,
    });
    return signUpResponse.data.user;
  } catch (error) {
    console.error("Signup Error:", error.message);
    throw error;
  }
};

export const requestPasswordReset = async (email) => {
  try {
    await axios.post(`${BASE_URL}/request-password-reset`, { email });
    return true;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (token, password) => {
  try {
    await axios.post(`${BASE_URL}/reset-password`, { token, password });
    return true;
  } catch (error) {
    throw error;
  }
};

const getAuthConfig = () => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const fetchVideos = async (category?: string) => {
  try {
    const url = category
      ? `${BASE_URL}/videos?category=${category}`
      : `${BASE_URL}/videos`;
    const { data } = await axios.get(url, getAuthConfig());
    return data;
  } catch (error) {
    console.error("Error fetching videos:", error);
    throw error;
  }
};

export const searchVideos = async (query) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/videos/search?search=${query}`
    );
    return data;
  } catch (error) {
    console.error("Error fetching videos:", error);
    throw error;
  }
};
